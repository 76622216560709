<div [class.centered-content]="centered" class="container">
  <div class="row">
    <div class="col-5"></div>
    <div class="col-2 text-center">
      <mat-spinner [diameter]="size" color="warn"></mat-spinner>
    </div>
    <div class="col-5"></div>
  </div>

</div>
