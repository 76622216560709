<div mat-dialog-title class="row">
  <div class="col-12 text-center">
    <h1>Törlés</h1>
  </div>
</div>
<div mat-dialog-content class="row my-3">
  <div class="col-12 text-center font-weight-bold">
    <h3>{{text}}</h3>
  </div>
</div>
<div mat-dialog-actions class="row mt-5 mb-1">
  <div class="col-6">
    <button (click)="onClick(false)" class="btn btn-outline-primary">
      Mégse
    </button>
  </div>
  <div class="col-6 text-right">
    <button (click)="onClick(true)" class="btn btn-outline-danger">
      Törlés
    </button>
  </div>
</div>

