import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {UserService} from '../../../Service/User/user.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationServiceService} from '../../../Service/notification-service/notification-service.service';
import {Subject} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm;
  private unsubscribeAll: Subject<any>;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    private notiService: NotificationServiceService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.unsubscribeAll = new Subject();
    dialogRef.disableClose = true;
    this.createForm();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  createForm() {
    this.loginForm = this.fb.group({
      userName: [''],
      password: ['']
    });
  }

  login() {
    this.userService.login(this.loginForm.getRawValue()).subscribe(
      res => {
        localStorage.setItem('accessToken', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        this.dialogRef.close(true);
        this.router.navigate(['/inventory']);
      },
      error => {
        this.notiService.error(error.errorMessage);
      }
    );

  }

  get userName() {
    return this.loginForm.get('userName') as FormArray;
  }

  get password() {
    return this.loginForm.get('password') as FormArray;
  }
}
