<div class="container-fluid">
  <div class="row py-3">
    <div class="col-6 mb-1">
      <h1>Felhasználók</h1>
    </div>
    <div class="col-6 text-right mb-3">
      <button class="btn btn-outline-success" (click)="openDialog([], false)">Új felhasználó létrehozása</button>
    </div>
  </div>
  <mat-card class="mb-3" *ngIf="!isLoading; else LoadingSpinner">
    <div class="row" >
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Szűrő</mat-label>
          <input matInput (keyup)="applyFilter($event)" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <div class="row" *ngIf="!isLoading;">
    <div class="col-12">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>


          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Azonosító</th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Felhasználó név</th>
            <td mat-cell *matCellDef="let row"> {{row.userName}}</td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Név</th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Beosztás</th>
            <td mat-cell *matCellDef="let row"> {{row.role !== 3 ? 'Beosztott' : 'Admin'}}</td>
          </ng-container>

          <ng-container matColumnDef="lastLogin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Utolsó bejelentkezés</th>
            <td mat-cell *matCellDef="let row"> {{row.lastLogin | date:'short'}}</td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row">
              <button class="btn btn-outline-danger delete" (click)="deleteUser($event)">Törlés</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row, true)">
          </tr>
        </table>

        <mat-paginator pageSize="10"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #LoadingSpinner>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>
