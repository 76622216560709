<div class="container">
  <div class="row">
    <div class="col-12 my-1 text-center">
<!--      <h1 class="loginTitle">Bejelentkezés</h1>-->
      <img src="../../../../assets/Images/croppedLogo.png" alt="asd">
    </div>
  </div>
  <form [formGroup]="loginForm">
    <div class="row">
      <div class="col-12 my-2">
        <mat-form-field appearance="outline">
          <mat-label>Felhasználónév</mat-label>
          <input formControlName="userName" matInput
                 placeholder="Pl. vdrobi">
<!--          <mat-hint>Errors appear instantly!</mat-hint>-->
        </mat-form-field>
      </div>
      <div class="col-12 mb-2">
        <mat-form-field appearance="outline">
          <mat-label>Jelszó</mat-label>
          <input type="password" formControlName="password" matInput
                 placeholder="Jelszó">
<!--          <mat-hint>Errors appear instantly!</mat-hint>-->
        </mat-form-field>
      </div>
    </div>

  <div class="row">
    <div class="col-12 text-right">
      <button class="btn btn-outline-primary" (click)="login()"> Bejelentkezés </button>
    </div>
  </div>
  </form>
</div>
