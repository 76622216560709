import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestService} from '../Rest/rest.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(
    private restService: RestService
  ) {
  }

  getStocks(): Observable<any> {
    return this.restService.get(`StockController/getStocks`)
      .pipe(map(response => response.getData()));
  }

  getStock(id): Observable<any> {
    return this.restService.get(`StockController/getStock?id=${id}`)
      .pipe(map(response => response.getData()));
  }

  removeFromStock(param, id, falling = false): Observable<any> {
    param.id = id;
    const path = falling ? `StockController/removeFallingProductFromStock` : `StockController/removeFromStock`;
    return this.restService.post(path, param)
      .pipe(map(response => response.getData()));
  }

  deleteFromStock(id): Observable<any> {
    console.log(id);
    return this.restService.get(`StockController/deleteFromStock?stockId=${id}`)
      .pipe(map(response => response.getData()));
  }

  saveStock(productId): Observable<any> {
    console.log(productId);
    return this.restService.get(`StockController/saveStock?productId=${productId}`)
      .pipe(map(response => response.getData()));
  }


}
