import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {UserService} from '../../../Service/User/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationServiceService} from '../../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit, OnDestroy {
  userForm;
  user;
  isUpdate;
  private unsubscribeAll: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<UserDialogComponent>,
              private fb: FormBuilder,
              private userService: UserService,
              private notiService: NotificationServiceService,
              private snackBar: MatSnackBar) {
    this.unsubscribeAll = new Subject();
    this.user = this.data.userData;
    this.isUpdate = this.data.isUpdate;
    this.createForm();
  }

  ngOnInit(): void {
    console.log(this.user);
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  createForm() {
    this.userForm = this.fb.group({
      userName: [this.user.userName, [this.isUpdate ? Validators.maxLength(20) : Validators.required]],
      id: [this.user.id],
      name: [this.user.name, [this.isUpdate ? Validators.maxLength(20) : Validators.required]],
      password: ['', [this.isUpdate ? Validators.maxLength(20) : Validators.required]],
      role: [this.user.role, [this.isUpdate ? Validators.max(3) : Validators.required]]

    });
  }

  get userName() {
    return this.userForm.get('userName') as FormArray;
  }

  get name() {
    return this.userForm.get('name') as FormArray;
  }

  get password() {
    return this.userForm.get('password') as FormArray;
  }

  get role() {
    return this.userForm.get('role') as FormArray;
  }

  save() {
    console.log(this.isUpdate);
    this.userService.registration(this.userForm.getRawValue(), this.isUpdate).subscribe(
      res => {
        this.notiService.success('Sikeresen beregisztráltad ' + this.userForm.controls.name.value + ' felhasználót.');
        this.dialogRef.close(true);
      },
      error => {
        this.notiService.error(error.errorMessage);
        console.log(error);
      }
    );
  }
}
