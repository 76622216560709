<div class="container-fluid">
  <div class="row pt-4 pb-3">
    <div class="col-12">
      <h1>Termék info történet</h1>
      <h4>A termék létrehozásával, törlésével, frissítésével kapcsolatas történetek láthatóak itt.</h4>
    </div>
  </div>
    <div class="row mb-2" *ngIf="!isLoading; else LoadingSpinner">
<!--  <div class="row">-->
    <div class="col-12 mb-2">
      <mat-card class="mb-2">
        <mat-form-field appearance="outline">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" autocomplete="off">
        </mat-form-field>
      </mat-card>
    </div>

    <div class="col-12" >
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Termék neve</th>
            <td mat-cell *matCellDef="let row" class="productName"> <strong>{{row.productName}} </strong></td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Felhasználó</th>
            <td mat-cell *matCellDef="let row" class="userName">  {{row.userName}}</td>
          </ng-container>
                    <!-- Progress Column -->
          <ng-container matColumnDef="isCreate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Művelet </th>
            <td mat-cell *matCellDef="let row"> {{row.isCreate }}</td>
          </ng-container>


          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dátum </th>
            <td mat-cell *matCellDef="let row"> {{row.created | date:'y, MMM, d'}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.isCreate == 'Törlés'? 'delete':''"  (click)="openHistory(row)">
          </tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,12,25,50,100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #LoadingSpinner>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>
