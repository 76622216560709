import { Injectable } from '@angular/core';
import {RestService} from '../Rest/rest.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private restService: RestService,
    private router: Router
  ) {
  }

  login(param): Observable<any> {
    return this.restService.post(`UserController/login`, param)
      .pipe(map(response => response.getData()));
  }
  getAuthToken(){
    return localStorage.getItem('accessToken');
  }
  getUser(){
    return JSON.parse(localStorage.getItem('user'));
  }
  public logout(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    window.location.reload();
    // this.router.navigate(['/']);
  }
  public registration(params, isUpdate): Observable<any> {
    const route = isUpdate ? 'UserController/updateUser' : 'UserController/registration';
    return this.restService.post(route, params)
      .pipe(map(response => response.getFirstData()));
  }


  public getUsers(): Observable<any>{
    return this.restService.get(`UserController/getUsers`)
      .pipe(map(response => response.getData()));
  }
  public getCurrentUser(id): Observable<any>{
    return this.restService.get(`UserController/getCurrentUser?userId=${id}`)
      .pipe(map(response => response.getData()));
  }
}
