<div class="container-fluid" *ngIf="!update; else LoadingSpinner" >
  <div class="pt-5">
    <mat-card >
    <div class="row mb-3">
      <div class="col-12 text-center pt-2">

        <h1 class="font-weight-bold font-italic my-0">{{user.name}}</h1>
        <h3 class=" font-italic mt-0">{{user.role !== 3 ? 'Beosztott' : 'Admin'}}</h3>
        <hr>
      </div>

    </div>

    <div class="row mb-3">
      <div class="col-6 text-center">
        <h3>Felhasználó név: <br> <strong>{{user.userName}}</strong></h3>
      </div>
      <div class="col-6 text-center">
        <h3>Utolsó bejelentkezés: <br> <strong>{{user.lastLogin | date: 'y, MMM, d'}} </strong></h3>
      </div>

    </div>
    <hr>
    <div class="row mb-3 mt-3">
      <div class="col-12 text-center">
        <h2>Változtasd meg az adataid</h2>
      </div>
    </div>
    <form [formGroup]="updateForm">
      <div class="row">
        <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12 text-center">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Felhasználó név</mat-label>
            <input matInput [placeholder]="user.userName" formControlName="userName" autocomplete="off">
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12 text-center">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Jelszó</mat-label>
            <input type="password" matInput formControlName="password">
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12 text-right">
        <button class="btn btn-outline-success" (click)="save()">
          Mentés
        </button>
      </div>
    </div>
  </mat-card>
  </div>
</div>

<ng-template #LoadingSpinner>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>
