import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {ProgressBarService} from './progress-bar.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent implements OnInit, OnDestroy {

  bufferValue: number;
  mode: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  value: number;
  visible: boolean;

  private unsubscribeAll: Subject<any>;

  constructor(private progressBarService: ProgressBarService)
  {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void
  {
    // Subscribe to the progress bar service properties

    // Buffer value
    this.progressBarService.bufferValue
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((bufferValue) => {
          this.bufferValue = bufferValue;
        });

    // Mode
    this.progressBarService.mode
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((mode) => {
          this.mode = mode;
        });

    // Value
    this.progressBarService.value
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((value) => {
          this.value = value;
        });

    // Visible
    this.progressBarService.visible
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((visible) => {
          this.visible = visible;
        });

  }
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


}
