import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {UserService} from '../User/user.service';
// import {NotificationService} from '../../main/notification/notification.service';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router,
                // private notificationService: NotificationService,
                private userService: UserService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!isNullOrUndefined(this.userService.getAuthToken())) {
            return true;
        } else {
            // this.notificationService.warning('Token is not present, redirecting to login.');
            // this.router.navigate(['']);
            return false;
        }
    }

}
