import { NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MatTreeModule} from '@angular/material/tree';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FilterComponent} from './filter/filter.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ProgressBarModule} from './progress-bar/progress-bar.module';
import { LoginComponent } from './login/login.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ProductListerComponent } from './product-lister/product-lister.component';


@NgModule({
  declarations: [
    NavbarComponent,
    DeleteDialogComponent,
    FilterComponent,
    LoginComponent,
    LoadingSpinnerComponent,
    ProductListerComponent
  ],
    imports: [
        CommonModule,
        MatSliderModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        MatTreeModule,
        MatDialogModule,
        MatCardModule,
        MatProgressBarModule,
        ProgressBarModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
    exports: [
        NavbarComponent,
        DeleteDialogComponent,
        FilterComponent,
        ProgressBarModule,
        LoginComponent,
        LoadingSpinnerComponent,
        ProductListerComponent
    ]
})
export class SharedModule {
}
