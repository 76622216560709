<div mat-dialog-title>
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="mb-1">{{this.stock.productName}}</h1>
      <h3>{{this.stock.product.company}}</h3>
      <hr>
    </div>
  </div>
</div>
<mat-dialog-content class="mat-typography content">
  <div class="row mb-5">
    <div class="col-4 text-center font-weight-bold"><h3 class="mb-1">Felület:</h3> {{this.stock.product.surface }}
    </div>
    <div class="col-4 text-center font-weight-bold"><h3 class="mb-1">Típus:</h3> {{this.stock.product.type }}</div>
    <div class="col-4 text-center font-weight-bold"><h3 class="mb-1">Termék
      szín:</h3> {{this.stock.product.productColor }}</div>
  </div>
  <div class="row">
    <div class="col-5"><h3>Mikor történt:</h3></div>
    <div class="col-7 text-right font-weight-bold">
      <h3>
        <strong>{{this.stock.created | date:'y, MMM, d'}}</strong>
      </h3>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="row mt-3 mb-5">
    <div class="col-5"><h3>Ki csinálta:</h3></div>
    <div class="col-7 text-right font-weight-bold"><h3><strong>{{this.stock.userName}}</strong></h3></div>
  </div>

  <div class="row mb-4">
    <div class="col-12 text-center">
      <mat-slide-toggle style="font-size: 17px" color="primary"
                        (change)="sliderChanged($event)">Hulló fóliát használsz?
      </mat-slide-toggle>
    </div>
  </div>
  <form class="example-container" [formGroup]="form" *ngIf="!slider">

    <div class="row my-2">
      <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12">
        <mat-form-field appearance="outline">
          <mat-label>Művelet</mat-label>
          <mat-select formControlName="operator">
            <mat-option value="remove">Levétel</mat-option>
            <mat-option value="add">Feltöltés</mat-option>
          </mat-select>
          <mat-error *ngIf="operator.invalid">Válassz egyet!</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12">
        <mat-form-field appearance="outline">
          <mat-label>Anyag hossza</mat-label>
          <input matInput placeholder="Pl. 3.5m" type="number" formControlName="size" min="0" max="100" autocomplete="off">
          <span matSuffix class="unit">m</span>
          <mat-error *ngIf="size.invalid">Max méret: 100m, Min. méret: 0m</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Mire kell?</mat-label>
          <textarea matInput placeholder="Pl. Baromfiudvar 10autó dekorálás" formControlName="reason" autocomplete="off"></textarea>
          <mat-error *ngIf="reason.invalid">Megadása kötelező (0-1000karakter).</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 text-center">
        Mentés után a termék új mennyisége:
        {{form.controls['operator'].value === 'add' ? stock.product.stock + form.controls['size'].value : stock.product.stock - form.controls['size'].value }}
        m
      </div>
    </div>
    <div class="row my-3"
         *ngIf="(form.controls['operator'].value === 'add'? stock.product.stock + form.controls['size'].value : stock.product.stock - form.controls['size'].value) < stock.product.criticalQuantity">
      <div class="col-12 text-center text-danger">
        <h2 class="text-danger mb-1">A termék kritikus szint alatt van!</h2>
        Jelenlegi mennyiség {{stock.product.stock }} m, kritikus szint: {{stock.product.criticalQuantity}} m
      </div>
    </div>
  </form>
  <div *ngIf="!loadingFallingProducts; else LoadingSpinnerForFalling">
    <form [formGroup]="fallingForm" *ngIf="slider">
      <div class="row my-1">
        <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Hullófóliák</mat-label>
            <mat-select formControlName="fallingProductId">
              <mat-option value="remove" *ngFor="let item of fallingProductsData" [value]="item">{{item.width}}
                x {{item.length}}</mat-option>
            </mat-select>
            <mat-error *ngIf="operator.invalid">Válassz egyet!</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Művelet</mat-label>
            <mat-select formControlName="operatorFall">
              <mat-option value="remove">Levétel</mat-option>
              <!--                <mat-option value="add">Feltöltés</mat-option>-->
            </mat-select>
            <mat-error *ngIf="operator.invalid">Válassz egyet!</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Anyag szélessége</mat-label>
            <input matInput type="number" placeholder="Pl. 3.5m" formControlName="width" min="0" max="2000" autocomplete="off">
            <span matSuffix class="unit">mm</span>
            <mat-error *ngIf="size.invalid">Max méret: 100m, Min. méret: 0m</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Anyag hossza</mat-label>
            <input matInput placeholder="Pl. 3.5m" type="number" formControlName="sizeFall" min="0" max="100" autocomplete="off">
            <span matSuffix class="unit">m</span>
            <mat-error *ngIf="size.invalid">Max méret: 100m, Min. méret: 0m</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Mire kell?</mat-label>
            <textarea matInput placeholder="Pl. Baromfiudvar 10 autó dekorálás" formControlName="reasonFall" autocomplete="off"></textarea>
            <mat-error *ngIf="reason.invalid">Megadása kötelező (0-1000karakter).</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="col-6 text-left mb-3">
      <button type="button" class="btn btn-outline-danger" [mat-dialog-close]="false">Mégse</button>
    </div>
    <div class="col-6 text-right mb-3">
      <button type="button" class="btn btn-outline-success" *ngIf="slider"
              [disabled]="fallingForm.invalid || (fallingForm.controls.sizeFall.value > fallingForm.controls.fallingProductId.value.length) ||
                  (fallingForm.controls.width.value > fallingForm.controls.fallingProductId.value.width) ||  saveClicked"
              (click)="save()" cdkFocusInitial>Mentés
      </button>
      <button type="button" class="btn btn-outline-success" *ngIf="!slider"
              [disabled]="form.invalid || (form.controls['operator'].value === 'add'? stock.product.stock + form.controls['size'].value : stock.product.stock - form.controls['size'].value) < 0 || saveClicked"
              (click)="save()" cdkFocusInitial>Mentés
      </button>
    </div>
</mat-dialog-actions>


<ng-template #loadingSpinner>
  <div class="container w-100 h-100">
    <div class="text-center">
      <app-loading-spinner [centered]="true"></app-loading-spinner>
    </div>
  </div>
</ng-template>
<ng-template #LoadingSpinnerForFalling>
  <div class="text-center">
    <app-loading-spinner ></app-loading-spinner>
  </div>
</ng-template>
