<h2 mat-dialog-title class="text-center" *ngIf="isUpdate">Frissítsd a te {{productInv.name}} terméked</h2>
<h2 mat-dialog-title class="text-center" *ngIf="!isUpdate">Hozz létre egy új terméket</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form" [formGroup]="invForm">
    <div class="row my-2">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Leltár id</mat-label>
          <input matInput disabled formControlName="id">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Gyártó neve</mat-label>
          <input type="text" disabled formControlName="company"
                 placeholder="Gyártó neve"
                 aria-label="Number"
                 matInput
                 [matAutocomplete]="auto">
          <mat-autocomplete class="single-autocomplete" #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredCompanies | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="company.invalid">
            A gyártó neve minimum <strong>0</strong> és maximum <strong>20 karakter</strong> lehet.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Termék név</mat-label>
          <input matInput placeholder="Pl. DPF 510 MT UT" formControlName="name" autocomplete="off">
          <mat-error *ngIf="name.invalid">
            A név minimum <strong>0</strong> és maximum <strong>25 karakter</strong> lehet.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline" (click)="stockClicked()">
          <mat-label>Készleten</mat-label>
          <input matInput placeholder="Pl. 3.5m" type="number" formControlName="stock" min="0" max="100" autocomplete="off">
          <span matSuffix class="unit">m</span>
          <mat-error *ngIf="stock.invalid">
            Készletnek legalább <strong>0 méter</strong> és maximum <strong>250 méter</strong> adható meg.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Felület</mat-label>
          <input matInput placeholder="Pl. matt" formControlName="surface"  [matAutocomplete]="auto1">
          <mat-autocomplete class="single-autocomplete" #auto1="matAutocomplete">
            <mat-option *ngFor="let surface of filteredSurfaces | async" [value]="surface">
              {{surface}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Minőség</mat-label>
          <input matInput placeholder="Pl. 1D" formControlName="quality" [matAutocomplete]="auto2">
          <mat-autocomplete class="single-autocomplete" #auto2="matAutocomplete">
            <mat-option *ngFor="let quality of filteredQualities | async" [value]="quality">
              {{quality}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Típus</mat-label>
          <input matInput placeholder="Pl. vinyl" formControlName="type"  [matAutocomplete]="auto3">
          <mat-autocomplete class="single-autocomplete" #auto3="matAutocomplete">
            <mat-option *ngFor="let type of filteredTypes | async" [value]="type">
              {{type}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Szélesség</mat-label>
          <input matInput placeholder="Pl. 1370 mm" type="number" formControlName="width" min="0" max="10000" autocomplete="off">
          <span matSuffix class="unit">mm</span>
          <mat-error *ngIf="!width.hasError('min') || !width.hasError('max')">
            Szélesség legalább <strong>0 mm</strong> és maximum <strong>10000 mm</strong> adható meg.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Fólia szin</mat-label>
          <input matInput placeholder="Pl. fehér" formControlName="productColor"  [matAutocomplete]="auto4">
          <mat-autocomplete class="single-autocomplete" #auto4="matAutocomplete">
            <mat-option *ngFor="let prodColor of filteredProdColors | async" [value]="prodColor">
              {{prodColor}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Ragasztó szín</mat-label>
          <input matInput placeholder="Pl. szürke" formControlName="glueColor"  [matAutocomplete]="auto5">
          <mat-autocomplete class="single-autocomplete" #auto5="matAutocomplete">
            <mat-option *ngFor="let glueColor of filteredGlueColors | async" [value]="glueColor">
              {{glueColor}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="user.role == 3">
          <mat-label>Egység ár</mat-label>
          <input matInput placeholder="Pl. 1200" type="number" formControlName="unitPrice" min="0" max="50000" autocomplete="off">
          <span matSuffix class="unit">Ft</span>
          <mat-error *ngIf="!unitPrice.hasError('min') || !unitPrice.hasError('max')">
            Egység ár legalább <strong>0 Ft</strong> és maximum <strong>50000 Ft</strong> adható meg.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline" >
          <mat-label>Kritikus mennyiség</mat-label>
          <input matInput placeholder="Pl. 35m" type="number" formControlName="criticalQuantity" min="0" max="250" autocomplete="off" >
          <span matSuffix class="unit">m</span>
          <mat-error *ngIf="!criticalQuantity.hasError('min') || !criticalQuantity.hasError('max')">
            Kritikus mennyiségnek legalább <strong>0 méter</strong> és maximum <strong>250 méter</strong> adható meg.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Helye</mat-label>
          <textarea matInput placeholder="Pl. 1.polc 15sor" formControlName="place" autocomplete="off"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="row w-100 my-3">
    <div class="col-6 text-left px-0">
      <button class="btn btn-outline-danger" [mat-dialog-close]="false">Mégse</button>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-outline-success" [disabled]="invForm.invalid" (click)="save()" cdkFocusInitial>Mentés</button>
    </div>
  </div>
</mat-dialog-actions>
