import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StockService} from '../../../Service/Stock/stock.service';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {DeleteDialogComponent} from '../../SharedModules/delete-dialog/delete-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FallingProductsService} from '../../../Service/falling-products/falling-products.service';
import {NotificationServiceService} from '../../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-set-product-stock',
  templateUrl: './set-product-stock.component.html',
  styleUrls: ['./set-product-stock.component.scss']
})
export class SetProductStockComponent implements OnInit, OnDestroy {
  productId;
  stock;
  form;
  fallingForm;
  noStock = false;
  loading = false;
  saveClicked = false;
  slider = false;
  private unsubscribeAll: Subject<any>;
  fallingProductsData: any;
  loadingFallingProducts = false;

  constructor(private stockService: StockService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private fallingProductsService: FallingProductsService,
              private router: Router,
              private dialog: MatDialog,
              private notiService: NotificationServiceService,
              private snackBar: MatSnackBar) {
    this.unsubscribeAll = new Subject();
    this.createForm();
  }

  ngOnInit(): void {
    this.loading = true;
    this.productId = this.route.snapshot.paramMap.get('productId');
    this.stockService.saveStock(this.productId).subscribe(
      res => {
        this.stock = res;
        console.log(res);
        this.loading = false;
        this.createForm();
      },
      error => {
        this.noStock = true;
        this.loading = false;
      }
    );
    console.log(this.stock);

  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  save() {
    this.saveClicked = true;
    const params = this.slider ? this.fallingForm.getRawValue() : this.form.getRawValue();
    this.stockService.removeFromStock(params, this.stock.stockId, this.slider).subscribe(res => {
        this.saveClicked = false;
        this.notiService.success('Sikeres mentés.');
        this.router.navigate(['/stock']);
      },
      error => {
        this.notiService.error(error.errorMessage);
        this.saveClicked = false;
      });
  }

  sliderChanged(event) {
    this.slider = event.checked;
    if (this.slider) {
      this.loadingFallingProducts = true;
      this.fallingProductsService.getFallingProductByProdId(this.stock.product.id).subscribe(
        res => {
          this.fallingProductsData = res;
          this.createFallingForm();
          this.loadingFallingProducts = false;
        },
        error => {
          this.loadingFallingProducts = false;
        }
      );
    }else{
      this.createForm();
    }

  }

  delete() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '30%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '450px' : '550px',
      data: {text: 'Biztosan törölni akarod a ' + this.stock.stockId + ' stock id-val rendelkező stockot?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stockService.deleteFromStock(this.stock.stockId).subscribe(
          res => {
            this.notiService.success('Sikeres törlés.');
            this.router.navigate(['/stock']);
          },
          error => {
            this.notiService.error(error.errorMessage);
            console.log(error);
          }
        );
      }
    });
  }

  createFallingForm(){
    this.fallingForm = this.fb.group({
      operatorFall: ['', Validators.required],
      fallingProductId: ['', Validators.required],
      sizeFall: ['', [Validators.max(100), Validators.min(0), Validators.required]],
      width: ['', [Validators.required, Validators.max(2000), Validators.min(0)]],
      reasonFall: ['', [Validators.maxLength(1000), Validators.min(0), Validators.required]]
    });
  }

  createForm() {
    this.form = this.fb.group({
      operator: ['', this.slider ? [] : Validators.required],
      size: ['', [Validators.max(100), Validators.min(0), Validators.required]],
      reason: ['', [Validators.maxLength(1000), Validators.min(0), Validators.required]]
    });
  }

  get operator() {
    return this.form.get('operator') as FormArray;
  }

  get size() {
    return this.form.get('size') as FormArray;
  }

  get reason() {
    return this.form.get('reason') as FormArray;
  }

  get operatorFall() {
    return this.form.get('operator') as FormArray;
  }

  get sizeFall() {
    return this.fallingForm.get('size') as FormArray;
  }

  get reasonFall() {
    return this.fallingForm.get('reason') as FormArray;
  }

  get fallingProductId() {
    return this.fallingForm.get('isFallingProduct') as FormArray;
  }

  get width() {
    return this.fallingForm.get('width') as FormArray;
  }

}
