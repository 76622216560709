import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {ProgressBarService} from '../../../Main/SharedModules/progress-bar/progress-bar.service';

@Injectable()
export class ProgressBarHandlerInterceptor implements HttpInterceptor {
  private onGoingCalls = 0;

  constructor(private progressBar: ProgressBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressBar.show();
    this.onGoingCalls++;
    return next
      .handle(req)
      .pipe(
        finalize(() => {
          if (--this.onGoingCalls === 0) {
            this.progressBar.hide();
          }
        })
      );
  }

}
