import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
// import {AuthenticationService} from '../../authentication/authentication.service';
import {isNullOrUndefined} from 'util';
import {UserService} from '../../User/user.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  isRefreshingToken = false;

  constructor(private userService: UserService,
              private router: Router,
            ) {
  }

  addToken(req: HttpRequest<any>, token: string = null): HttpRequest<any> {
    // return req.clone({setHeaders: {'Authorization': token}});
    if (isNullOrUndefined(token)) {
      token = isNullOrUndefined(this.userService.getAuthToken()) ? 'null' : this.userService.getAuthToken();
    }
    return req.clone({setHeaders: {Authorization: token}});
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = isNullOrUndefined(this.userService.getAuthToken()) ? 'null' : this.userService.getAuthToken() ;
    // request = this.addToken(request);
    request = request.clone({setHeaders: {Authorization: token}});
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          console.log('err', error);
          switch (error.status) {
            case 401:
              return this.handle401Error(request, next);
            default:
              return throwError(error);
          }

        } else {
          return throwError(error);
        }
      })
    );
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler){
    console.log('handle 401');
    // this.notificationService.warning('Your token is expired. Please login again.');
    this.userService.logout();
    // if (!this.isRefreshingToken) {
    //   this.isRefreshingToken = true;
    //   // return this.authService.refreshToken().pipe(
    //   //     switchMap((newToken: string) => {
    //   //         console.log('newToken', newToken);
    //   //         if (newToken) {
    //   //             return next.handle(this.addToken(request, newToken));
    //   //         }
    //   //
    //   //         this.authService.logout();
    //   //     }),
    //   //     catchError((error) => {
    //   //         console.log('error', error);
    //   //         this.authService.logout();
    //   //         return throwError(error);
    //   //     }),
    //   //     tap(data => console.log(data))
    //   // );
    //   this.authService.refreshToken().subscribe(
    //     newToken => {
    //       console.log('newToken', newToken);
    //       if (newToken) {
    //         return next.handle(this.addToken(request, newToken));
    //       }
    //       this.authService.logout();
    //     },
    //     error => {
    //       console.log('error', error);
    //       this.authService.logout();
    //       return throwError(error);
    //     }
    //   );
    // }
    return next.handle(this.addToken(request, this.userService.getAuthToken()));
  }

}




