import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './Main/SharedModules/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatChipsModule} from '@angular/material/chips';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryComponent } from './Main/Inventory/inventory.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpHandler} from '@angular/common/http';
import { StockComponent } from './Main/stock/stock.component';
import { StockDialogComponent } from './Main/stock/stock-dialog/stock-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HistoryComponent } from './Main/history/history.component';
import { ProductUpdateDialogComponent } from './Main/Inventory/product-update-dialog/product-update-dialog.component';
import {SetProductStockComponent} from './Main/stock/set-product-stock/set-product-stock.component';
import {MatTreeModule} from '@angular/material/tree';
import { TestComponent } from './test/test.component';
import {ProgressBarHandlerInterceptor} from './Service/Rest/interceptor/progress-bar-handler-interceptor.service';
import {TokenInterceptor} from './Service/Rest/interceptor/token-interceptor';
import { UsersComponent } from './Main/users/users.component';
import { UserDialogComponent } from './Main/users/user-dialog/user-dialog.component';
import { HistoryDialogComponent } from './Main/history/history-dialog/history-dialog.component';
import { ProfileComponent } from './Main/profile/profile.component';
import { InfoDialogComponent } from './Main/Inventory/info-dialog/info-dialog.component';
import { FallingDialogComponent } from './Main/Inventory/falling-dialog/falling-dialog.component';
import {InfoHistoryDialogComponent} from './Main/product-info-change-history/info-history-dialog/info-history-dialog.component';
import {ProductInfoChangeHistoryComponent} from './Main/product-info-change-history/product-info-change-history.component';

@NgModule({
  declarations: [
    AppComponent,
    InventoryComponent,
    StockComponent,
    StockDialogComponent,
    HistoryComponent,
    ProductUpdateDialogComponent,
    SetProductStockComponent,
    TestComponent,
    UsersComponent,
    UserDialogComponent,
    HistoryDialogComponent,
    ProfileComponent,
    InfoDialogComponent,
    FallingDialogComponent,
    ProductInfoChangeHistoryComponent,
    InfoHistoryDialogComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // ANGULAR MATERIAL MODULES
        MatCheckboxModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        MatTreeModule
    ],
  exports: [
    StockDialogComponent,
    UserDialogComponent
  ],
  providers: [
    HttpClient,
    HttpClientModule,
    {provide: HTTP_INTERCEPTORS, useClass: ProgressBarHandlerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

