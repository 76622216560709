<div class="row mb-2">
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Termék id: </span><br class="d-none d-md-block"><strong>{{product.id}}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Termék név: </span><br class="d-none d-md-block"><strong>{{product.name}}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Cég: </span><br class="d-none d-md-block"><strong>{{product.company}}</strong></div>

  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Leltár: </span><br class="d-none d-md-block"><strong>{{product.stock}} m</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Kritikus szint: </span><br class="d-none d-md-block"><strong>{{product.criticalQuantity}} m</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Szélesség </span><br class="d-none d-md-block"><strong>{{product.width }} mm</strong></div>

  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Termék szín: </span><br class="d-none d-md-block"><strong>{{product.productColor}}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Ragasztó: </span><br class="d-none d-md-block"><strong>{{product.glueColor}}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Minőség: </span><br class="d-none d-md-block"><strong>{{product.quality}}</strong></div>

  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Felület:</span> <br class="d-none d-md-block"><strong>{{product.surface}}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Típus: </span><br class="d-none d-md-block"><strong>{{product.type}}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>{{user.role == 3? 'Egység ár':'Státusz'}}</span>
    <br class="d-none d-md-block"><strong>{{user.role == 3? product.unitPrice + ' Ft' : (product.isActive == 1? 'Aktív' : 'Inaktív')}}</strong></div>
</div>
<div class="row mb-2">
  <div class="col-12 d-flex justify-content-between d-sm-block text-center"><span>Hely:</span>  <br class="d-none d-md-block"><strong>{{product.place}}</strong></div>
</div>
<div class="row mb-2" *ngIf="showDate">
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Létrehozva:</span>  <br class="d-none d-md-block"><strong>{{product.created }}</strong>
  </div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Törölve:</span>  <br class="d-none d-md-block"><strong>{{product.deleted }}</strong></div>
</div>
<div class="row mb-2" *ngIf="showDate">
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Módosítva:</span>
    <br><strong>{{product.lastModified }}</strong></div>
  <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"><span>Státusz változás:</span>  <br class="d-none d-md-block"><strong>{{product.activeChanged }}</strong></div>
</div>

