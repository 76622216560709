import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {InventoryService} from '../../Service/Inventory/inventory.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductUpdateDialogComponent} from './product-update-dialog/product-update-dialog.component';
import {FormArray, FormBuilder} from '@angular/forms';
import {Subject} from 'rxjs';
import {UserService} from '../../Service/User/user.service';
import {DeleteDialogComponent} from '../SharedModules/delete-dialog/delete-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {FallingDialogComponent} from './falling-dialog/falling-dialog.component';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {saveAs as importedSaveAs} from 'file-saver';
import {NotificationServiceService} from '../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, OnDestroy {
  inventory;
  productsDetails;
  private fileSaver = importedSaveAs;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  filterForm: any;
  totalCount = 12;
  user;
  isLoading = false;
  private unsubscribeAll: Subject<any>;
  innerWidth = window.innerWidth;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private notiService: NotificationServiceService,
    private router: Router
  ) {
    this.createForm();
    this.unsubscribeAll = new Subject();
    this.user = this.userService.getUser();
  }

  ngOnInit(): void {
    this.getCompanies();
    this.getInventory(innerWidth > 750 ? 12 : 6);

  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getCompanies() {
    this.inventoryService.getCompanies().subscribe(
      res => {
        console.log(res.companies);
        this.productsDetails = res;
      }
    );
  }

  createForm() {
    this.filterForm = this.fb.group({
      company: [null],
      type: [null],
      searchString: [null],
      sizeOptions: [null],
      status: [[1]],
      onlyCritical: [false]
    });

  }

  getInventory(pageSize, pageIndex = 1) {
    this.isLoading = true;
    this.inventoryService.getInventory(pageSize, pageIndex, this.filterForm.getRawValue()).subscribe(
      res => {
        this.inventory = res.products;
        this.totalCount = res.total;
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  openUpdateDialog(inv, operator) {
    let res = true;
    console.log(Object.keys(inv).length === 0);
    if (!(Object.keys(inv).length === 0) && operator === 'add') {
      res = confirm('Biztos klónozni akarod a ' + inv.name + ' terméket?');
    }
    if (res) {
      const dialogRef = this.dialog.open(ProductUpdateDialogComponent, {
        width: '40%',
        minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '500px' : '700px',
        data: {
          productInv: inv,
          operator,
          productDetails: this.productsDetails
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getInventory(innerWidth > 750 ? 12 : 6);
          this.getCompanies();
        }
      });
    }
  }

  openInfoDialog(prod) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '40%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '500px' : '700px',
      data: {
        product: prod,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFallingProductsDialog(prod){
    this.dialog.open(FallingDialogComponent, {
      width: '40%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '500px' : '700px',
      minHeight: '300px',
      data: {
        product: prod,
      }
    });
  }

  statusChanged(event, inv) {
    const stat = event.checked ? 1 : 0;
    inv.isActive = stat;
    const params = {
      isActive: stat,
      id: inv.id
    };
    this.inventoryService.setIsActive(params).subscribe(
      res => {
        this.notiService.success('Sikeres mentés.');
        this.getInventory(innerWidth > 750 ? 12 : 6);
      },
      error => {
        this.notiService.error(error.errorMessage);
        console.log(error);
      }
    );
  }

  deleteProduct(id: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '30%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '450px' : '550px',
      data: {text: 'Biztosan törölni akarod a termékek közül a ' + id + ' termék id-val rendelkező terméked?'}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res === true) {
        this.inventoryService.deleteProduct(id).subscribe(
          () => {
            this.notiService.success('Sikeres törlés.');
            this.getInventory(innerWidth > 750 ? 12 : 6);
          },
          error => {
            this.notiService.error(error.errorMessage);
            console.log(error);
          }
        );
      }
    });
  }

  areYouSureAlert(inv) {
    if (confirm('Biztos stockhoz szeretnéd adni a ' + inv.name + ' terméket?')) {
      this.takeAPicture(inv);
    }

  }

  takeAPicture(row) {
    this.router.navigate(['/stock/' + row.id]);
  }

  paginate($event: PageEvent) {
    console.log($event);
    const pageIndex = $event.pageIndex + 1;
    const pageSize = $event.pageSize;
    this.getInventory(pageSize, pageIndex);
  }


  export() {
    this.inventoryService.export(this.filterForm.getRawValue()).subscribe(
      res => {
        const today = new Date();
        const blob = new Blob([res], {type: 'text/csv;charset=utf-8'});
        this.fileSaver.saveAs(blob, `termek_export_${today.getUTCMonth() + 1}_${today.getDate()}_${today.getFullYear()}.csv`);
      },
      error => {
        console.log(error);
        // this.isLoading = false;
      }
    );
  }

  get company() {
    return this.filterForm.get('company') as FormArray;
  }

  get type() {
    return this.filterForm.get('type') as FormArray;
  }

  get searchString() {
    return this.filterForm.get('searchString') as FormArray;
  }

  get sizeOptions() {
    return this.filterForm.get('sizeOptions') as FormArray;
  }

  get status() {
    return this.filterForm.get('status') as FormArray;
  }

  get onlyCritical() {
    return this.filterForm.get('onlyCritical') as FormArray;
  }


}
