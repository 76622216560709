import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {StockService} from '../../../Service/Stock/stock.service';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FallingProductsService} from '../../../Service/falling-products/falling-products.service';
import {NotificationServiceService} from '../../../Service/notification-service/notification-service.service';
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-stock-dialog',
  templateUrl: './stock-dialog.component.html',
  styleUrls: ['./stock-dialog.component.scss']
})
export class StockDialogComponent implements OnInit, OnDestroy {
  stock;
  form;
  fallingForm;
  slider = false;
  loading = false;
  saveClicked = false;
  private unsubscribeAll: Subject<any>;
  fallingProductsData: any;
  loadingFallingProducts = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<StockDialogComponent>,
              private stockService: StockService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private fallingProductsService: FallingProductsService,
              private notiService: NotificationServiceService,
              private snackBar: MatSnackBar) {
    this.unsubscribeAll = new Subject();
  }


  ngOnInit(): void {
    this.stock = this.data.stock;
    console.log(this.stock);
    this.createForm();
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }


  createFallingForm(){
    this.fallingForm = this.fb.group({
      operatorFall: ['', Validators.required],
      fallingProductId: ['', Validators.required],
      sizeFall: ['', [Validators.max(100), Validators.min(0), Validators.required]],
      width: ['', [Validators.required, Validators.max(2000), Validators.min(0)]],
      reasonFall: ['', [Validators.maxLength(1000), Validators.min(0), Validators.required]]
    });
  }

  createForm() {
    this.form = this.fb.group({
      operator: ['', this.slider ? [] : Validators.required],
      size: ['', [Validators.max(100), Validators.min(0), Validators.required]],
      reason: ['', [Validators.maxLength(1000), Validators.min(0), Validators.required]]
    });
  }
  get operator() {
    return this.form.get('operator') as FormArray;
  }

  get size() {
    return this.form.get('size') as FormArray;
  }

  get reason() {
    return this.form.get('reason') as FormArray;
  }

  get operatorFall() {
    return this.form.get('operator') as FormArray;
  }

  get sizeFall() {
    return this.fallingForm.get('size') as FormArray;
  }

  get reasonFall() {
    return this.fallingForm.get('reason') as FormArray;
  }

  get fallingProductId() {
    return this.fallingForm.get('isFallingProduct') as FormArray;
  }

  get width() {
    return this.fallingForm.get('width') as FormArray;
  }

  save() {
    this.saveClicked = true;
    const params = this.slider ? this.fallingForm.getRawValue() : this.form.getRawValue();
    this.stockService.removeFromStock(params, this.stock.stockId, this.slider).subscribe(res => {
        this.saveClicked = false;
        this.notiService.success('Sikeres mentés.');
        this.dialogRef.close(true);
        // this.router.navigate(['/stock']);
      },
      error => {
        this.notiService.error(error.errorMessage);
        this.saveClicked = false;
        this.dialogRef.close(false);
      });
  }


  sliderChanged(event) {

    if (event.checked) {
      this.loadingFallingProducts = true;
      this.fallingProductsService.getFallingProductByProdId(this.stock.product.id).subscribe(
        res => {
          this.fallingProductsData = res;
          this.createFallingForm();
          this.loadingFallingProducts = false;
        },
        error => {
          this.loadingFallingProducts = false;
        }
      );
    }else{
      this.createForm();
    }
    this.slider = event.checked;
  }
}
