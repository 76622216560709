import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Subject} from 'rxjs';
import {HistoryService} from '../../Service/History/history.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {HistoryDialogComponent} from '../history/history-dialog/history-dialog.component';
import {InfoHistoryDialogComponent} from './info-history-dialog/info-history-dialog.component';

@Component({
  selector: 'app-product-info-change-history',
  templateUrl: './product-info-change-history.component.html',
  styleUrls: ['./product-info-change-history.component.scss']
})
export class ProductInfoChangeHistoryComponent implements OnInit, OnDestroy {
  dataSource;
  isLoading = false;
  columnMobile = ['productName', 'isCreate'];
  columnlaptop = ['userName', 'productName', 'isCreate', 'created'];
  displayedColumns = innerWidth < 600 ? this.columnMobile : this.columnlaptop;
  private paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  private unsubscribeAll: Subject<any>;
  constructor(
    private historyService: HistoryService,
    private dialog: MatDialog
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.getHistory();
  }
  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
  getHistory(){
    this.isLoading = true;
    this.historyService.getProductInfoChangeHistory().subscribe(
      res => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(res);
        this.setDataSourceAttributes();
      },
      error => {
        this.isLoading = false;
      }
    );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openHistory(row){
    const dialogRef = this.dialog.open(InfoHistoryDialogComponent, {
      width: '40%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '500px' : '700px',
      data: {
        history: row
      }
    });
    dialogRef.afterClosed().subscribe(res => {
    });
  }

}
