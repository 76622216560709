import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../User/user.service';
import {isNullOrUndefined} from 'util';
import {NotificationServiceService} from '../notification-service/notification-service.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private notiService: NotificationServiceService,
              private userService: UserService,
              private router: Router) {
  }

  canActivate(): boolean {
    console.log(this.userService.getUser().role);
    console.log(!isNullOrUndefined(this.userService.getAuthToken()));
    if (!isNullOrUndefined(this.userService.getAuthToken())
      && this.userService.getUser().role === 3) {
      // You are admin, go through!
      return true;
    }
    this.notiService.error('Nincs jogosultságod!');
    this.router.navigate(['/inventory']);
    return false;
  }
}
