<mat-sidenav-container class="sidenav-container" >
  <mat-sidenav #drawer class="sidenav" [class.bordered]="(isHandset$ | async)" fixedInViewport
               [disableClose]="!(isHandset$ | async)"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <img src="assets/Images/croppedLogo.png" width="100%" class="logo">
    </mat-toolbar>
    <mat-nav-list class="navlist" *ngIf="user">
      <a mat-list-item (click)="navigationClicked()" routerLink="/users" *ngIf="user.role === 3">Felhasználók</a>
      <a mat-list-item (click)="navigationClicked()" routerLink="/prodHistory">Termék használat</a>
      <a mat-list-item (click)="navigationClicked()" routerLink="/prodInfoHistory"  *ngIf="user.role === 3">Termék info változás</a>
      <a mat-list-item (click)="navigationClicked()" routerLink="/inventory">Leltár</a>
      <a mat-list-item (click)="navigationClicked()" routerLink="/stock" >Stock</a>
      <a mat-list-item (click)="navigationClicked()" class="footerPosition1" routerLink="/profile" >Profil</a>
      <a mat-list-item (click)="logout()" class="footerPosition" >Kilépés</a>


    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar *ngIf="(isHandset$ | async)" class="ContentToolbar" [ngClass]="(isHandset$ | async) ? 'mobileBackground' : 'largeBackground' ">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
    </mat-toolbar>

    <div class="routerContainer">
      <div class="routerCont">
        <div class="w-100 h-100">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
