<div class="container-fluid">
  <div mat-dialog-title class="row">
    <div class="col-12 text-center">
      <h1 class="mb-1">{{history.userName}}</h1>
      <h3><strong>{{history.productName}}</strong>
        - {{history.isCreate}}</h3>
      <hr>
    </div>
  </div>
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <h2>Felhasználó adatok</h2>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center">Felhasználó id: <br><strong>{{history.user.id}}</strong></div>
      <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center">Felhasználó név: <br><strong>{{history.user.name}}</strong></div>
      <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center">Beosztás: <br><strong>{{history.user.role === 3 ? 'Admin' : 'Beosztott'}}</strong></div>
    </div>
    <div class="row">
      <div class="col-12 mt-1">
        <h2>Termék adatok</h2>
      </div>
    </div>
    <ng-container *ngIf="history.isCreate != 'Törlés';else deleteProd">
      <app-product-lister [showDate]="false" [product]="history.product"></app-product-lister>


    </ng-container>

    <div class="row">
      <div class="col-12 mt-1">
        <h2>Régi termék adatok és változás</h2>
      </div>
    </div>
    <ng-container *ngIf="history.isCreate != 'Létrehozás';else newProd">
      <div class="row mb-1">
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.id !== history.product.id? 'notEqual' : ''"><span>Termék id:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.id}}</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.name !== history.product.name? 'notEqual' : ''"><span>Termék név:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.name}}</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.company !== history.product.company? 'notEqual' : ''"><span>Cég:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.company}}</strong></div>

        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.stock !== history.product.stock? 'notEqual' : ''"><span>Leltár:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.stock}} m</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.criticalQuantity !== history.product.criticalQuantity? 'notEqual' : ''">
          <span>Kritikus szint: </span><br class="d-none d-md-block"><strong>{{history.oldProduct.criticalQuantity}} m</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.width !== history.product.width? 'notEqual' : ''"><span>Szélesség</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.width }} mm</strong></div>

        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.productColor !== history.product.productColor? 'notEqual' : ''"><span>Termék szín:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.productColor}}</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.glueColor !== history.product.glueColor? 'notEqual' : ''"><span>Ragasztó:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.glueColor}}</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.quality !== history.product.quality? 'notEqual' : ''"><span>Minőség:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.quality}}</strong></div>

        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.surface !== history.product.surface? 'notEqual' : ''"><span>Felület:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.surface}}</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.type !== history.product.type? 'notEqual' : ''"><span>Típus:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.type}}</strong></div>
        <div class="col-sm-12 col-md-4 col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.isActive !== history.product.isActive? 'notEqual' : ''"><span>Státusz:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.isActive}}</strong></div>
      </div>
      <div class="row mb-2">
        <div class="col-12 mb-2 d-flex justify-content-between d-sm-block text-center"
             [ngClass]="history.oldProduct.place !== history.product.place? 'notEqual' : ''"><span>Hely:</span>
          <br class="d-none d-md-block"><strong>{{history.oldProduct.place}}</strong></div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="col-12 mb-2">
      <hr>
      <button class="cancelButton btn btn-outline-danger" mat-dialog-close>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
<ng-template #newProd>
  <div class="row">
    <div class="col-12 text-center">
      <h3>Termék létrehozásánál, nincs régi termék adat.</h3>
    </div>
  </div>
</ng-template>
<ng-template #deleteProd>
  <div class="row">
    <div class="col-12 text-center">
      <h3>Termék törlésénél, nincs új termék adat.</h3>
    </div>
  </div>
</ng-template>
