import { Injectable } from '@angular/core';
import {Observable, pipe, UnaryFunction} from 'rxjs';
import {environment as env} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RestResponse} from './rest-response.service';
import {catchError, map, tap} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {RequestError} from './request-error.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) { }

  private static checkResponse(response: RestResponse): void {
    if (!response.isSuccess()) {
      throw response;
    }
  }

  public get(url: string, params?): Observable<RestResponse> {
    return this.http.get<any>(env.restURL + url, {params})
      .pipe(this.processResponse());
  }

  public post(url: string, body: any, params?: { headers: HttpHeaders }){
    return this.http.post<any>(env.restURL + url, body, params)
      .pipe(this.processResponse());
  }

  private processResponse(): UnaryFunction<Observable<any>, Observable<RestResponse>> {
    return pipe(
      map(RestResponse.create),
      tap( (response) => RestService.checkResponse(response)),
      catchError((err: any) => this.handleError(err))
    );
  }
  private handleError(err: any): never {
    if (!isNullOrUndefined(err.errorMessage)) {
      // this.notificationService.error(err.errorMessage);
    }
    throw RequestError.create(err) || err;
  }

}
