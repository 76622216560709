import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {InventoryService} from '../../../Service/Inventory/inventory.service';
import {Observable, Subject} from 'rxjs';
import {UserService} from '../../../Service/User/user.service';
import {map, startWith} from 'rxjs/operators';
import {MatDrawer} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationServiceService} from '../../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-inventory-update-dialog',
  templateUrl: './product-update-dialog.component.html',
  styleUrls: ['./product-update-dialog.component.scss']
})
export class ProductUpdateDialogComponent implements OnInit, OnDestroy {
  invForm;
  productInv;
  dialogFor;
  isUpdate;
  stockClickedBool = false;
  user;
  companies: string[];
  types: string[];
  surfaces: string[];
  qualities: string[];
  prodColors: string[];
  glueColors: string[];
  filteredCompanies: Observable<string[]>;
  filteredTypes: Observable<string[]>;
  filteredSurfaces: Observable<string[]>;
  filteredProdColors: Observable<string[]>;
  filteredGlueColors: Observable<string[]>;
  filteredQualities: Observable<string[]>;
  private unsubscribeAll: Subject<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<ProductUpdateDialogComponent>,
              private inventoryService: InventoryService,
              private fb: FormBuilder,
              private userService: UserService,
              private notiService: NotificationServiceService,
              private snackBar: MatSnackBar) {
    this.productInv = this.data.productInv;
    console.log(this.data.productDetails);
    this.dialogFor = this.data.operator;
    this.companies = this.data.productDetails.companies;
    this.types = this.data.productDetails.types;
    this.surfaces = this.data.productDetails.surfaces;
    this.qualities = this.data.productDetails.qualities;
    this.prodColors = this.data.productDetails.prodColors;
    this.glueColors = this.data.productDetails.glueColors;
    // Set the private defaults
    this.unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.isUpdate = this.dialogFor === 'update';
    this.user = this.userService.getUser();
    this.createForm();

    this.filteredCompanies = this.invForm.get('company').valueChanges
      .pipe(
        startWith(''),
        map(value => this._companyFilter(value))
      );
    this.filteredGlueColors = this.invForm.get('glueColor').valueChanges
      .pipe(
        startWith(''),
        map(value => this._glueColorFilter(value))
      );
    this.filteredProdColors = this.invForm.get('productColor').valueChanges
      .pipe(
        startWith(''),
        map(value => this._productColoeFilter(value))
      );
    this.filteredSurfaces = this.invForm.get('surface').valueChanges
      .pipe(
        startWith(''),
        map(value => this._surfaceFilter(value))
      );
    this.filteredTypes = this.invForm.get('type').valueChanges
      .pipe(
        startWith(''),
        map(value => this._typeFilter(value))
      );
    this.filteredQualities = this.invForm.get('quality').valueChanges
      .pipe(
        startWith(''),
        map(value => this._qualityFilter(value))
      );

  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private _companyFilter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.companies.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _qualityFilter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.qualities.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _typeFilter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.types.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _surfaceFilter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.surfaces.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _productColoeFilter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.prodColors.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _glueColorFilter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.glueColors.filter(option => option.toLowerCase().includes(filterValue));
  }

  save() {
    this.inventoryService.pruductChange(this.dialogFor, this.invForm.getRawValue()).subscribe(
      res => {
        this.notiService.success('Sikeres mentés.');
        this.dialogRef.close(true);
      },
      error => {
        console.log(error);
        this.notiService.error(error.errorMessage);
        console.log(error);
      }
    );

  }


  stockClicked() {
    if (!this.stockClickedBool && this.dialogFor === 'update' && this.user.role === 3) {
      this.stockClickedBool = true;
      alert('Biztos módosítani akarsz a készleten?');
    }

  }

  createForm() {
    this.invForm = this.fb.group({
      id: [{value: this.isUpdate ? this.productInv.id : null, disabled: true}],
      company: [this.productInv.company, [Validators.required, Validators.maxLength(20)]],
      name: [this.isUpdate ? this.productInv.name : null, [Validators.required, Validators.maxLength(25)]],
      stock: [{
        value: this.isUpdate ? this.productInv.stock : null,
        disabled: !(this.user.role === 3 || this.dialogFor === 'add')
      }, [Validators.required, Validators.max(250), Validators.min(0)]],
      surface: [this.productInv.surface],
      quality: [this.productInv.quality],
      type: [this.productInv.type],
      width: [this.productInv.width, [Validators.max(10000), Validators.min(0)]],
      productColor: [this.productInv.productColor],
      glueColor: [this.productInv.glueColor],
      unitPrice: [{
        value: this.productInv.unitPrice,
        disabled: !(this.user.role === 3 || this.dialogFor === 'add')
      }, [Validators.max(50000), Validators.min(0)]],
      place: [this.productInv.place],
      criticalQuantity: [this.productInv.criticalQuantity, [Validators.max(250), Validators.min(0)]]
    });
  }

  get unitPrice() {
    return this.invForm.get('unitPrice') as FormArray;
  }

  get surface() {
    return this.invForm.get('surface') as FormArray;
  }

  get place() {
    return this.invForm.get('place') as FormArray;
  }

  get quality() {
    return this.invForm.get('quality') as FormArray;
  }

  get type() {
    return this.invForm.get('type') as FormArray;
  }

  get width() {
    return this.invForm.get('width') as FormArray;
  }

  get productColor() {
    return this.invForm.get('productColor') as FormArray;
  }

  get glueColor() {
    return this.invForm.get('glueColor') as FormArray;
  }

  get isActive() {
    return this.invForm.get('isActive') as FormArray;
  }

  get criticalQuantity() {
    return this.invForm.get('criticalQuantity') as FormArray;
  }

  get stockChanged() {
    return this.invForm.get('stockChanged') as FormArray;
  }

  get activeChanged() {
    return this.invForm.get('activeChanged') as FormArray;
  }

  get operator() {
    return this.invForm.get('operator') as FormArray;
  }

  get id() {
    return this.invForm.get('prodId') as FormArray;
  }

  get company() {
    return this.invForm.get('company') as FormArray;
  }

  get size() {
    return this.invForm.get('size') as FormArray;
  }

  get name() {
    return this.invForm.get('name') as FormArray;
  }

  get stock() {
    return this.invForm.get('stock') as FormArray;
  }

  get created() {
    return this.invForm.get('created') as FormArray;
  }

  get lastModified() {
    return this.invForm.get('lastModified') as FormArray;
  }


}
