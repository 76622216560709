import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestService} from '../Rest/rest.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private restService: RestService
  ) {
  }

  getProductUsageHistory(): Observable<any> {
    return this.restService.get(`HistoryController/getHistory`)
      .pipe(map(response => response.getData()));
  }
  getProductInfoChangeHistory(): Observable<any> {
    return this.restService.get(`ProductInfoChangeController/getHistory`)
      .pipe(map(response => response.getData()));
  }
}
