<div class="container-fluid">
  <div class="row">
    <div class="col-12" mat-dialog-title>
      <h1 class="my-0 text-center">{{product.name}}</h1>
      <h3 class="my-0 text-center">{{product.company}}</h3>
      <hr>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-12">
        <h1>Termék infó</h1>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 d-flex justify-content-around text-primary">
        <h2>Leltáron: </h2>
        <h2><strong>{{ product.stock }} m</strong></h2>
      </div>
    </div>
    <app-product-lister [product]="product" [showDate]="true"></app-product-lister>
    <div class="row" *ngIf="false">
      <div class="col-12">
        <h2>Hulló fólia infók</h2>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-1">

    <div class="col-12 mb-3">
      <hr>
      <button class="btn btn-outline-danger" [mat-dialog-close]="false">Mégse</button>
    </div>
    <div class="col-6 text-right mb-3" *ngIf="false">
      <hr>
      <button class="btn btn-outline-primary" [mat-dialog-close]="true">Hulló fóliák</button>
    </div>
  </mat-dialog-actions>
</div>
