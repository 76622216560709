<div class="container-fluid">
  <div mat-dialog-title class="row">
    <div class="col-12 text-center">
      <h1 class="mb-1">{{history.userName}}</h1>
      <h3>{{history.productName}}</h3>
      <hr>
    </div>
  </div>
  <mat-dialog-content>

    <div class="row">
      <div class="col-12 mt-1">
        <h2>Termék adatok</h2>
      </div>
    </div>
    <app-product-lister [showDate]="false" [product]="history.product"></app-product-lister>


    <div class="row">
      <div class="col-12">
        <h2>Stock készítő adatok</h2>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-4 text-center">Felhasználó id: <br><strong>{{history.user.id}}</strong></div>
      <div class="col-4 text-center">Felhasználó név: <br><strong>{{history.user.name}}</strong></div>
      <div class="col-4 text-center">Beosztás: <br><strong>{{history.user.role === 3 ? 'Admin' : 'Beosztott'}}</strong></div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <h2>History adat</h2>
      </div>
    </div>
    <div class="row mb-1" *ngIf="history.operation !== null">
      <div class="col-4 text-center">Művelet: <br><strong>{{history.operation === 'add' ? 'Feltöltött' : 'Levett'}}</strong></div>
      <div class="col-4 text-center">Méret: <br><strong>{{history.size | number}} m</strong></div>
      <div class="col-4 text-center" *ngIf="history.isFalling == 1">Szélesség:<br> <strong>{{history.width}} mm</strong></div>
    </div>
    <div class="row mb-1" *ngIf="history.operation !== null">
      <div class="col-4 text-center">Ok:<br> <strong>{{history.reason}}</strong></div>
      <div class="col-4 text-center">Mikor: <br><strong>{{history.productUsedAt |date: 'y, MMM, d'}}</strong></div>
      <div class="col-4 text-center" >Mentve: <br><strong>{{history.savedAt |date:'y, MMM, d'}}</strong></div>
    </div>
    <div class="row mb-1" *ngIf="history.operation == null">
      <div class="col-12 text-center">
        <h2>A stock törlve lett.</h2>
      </div>
    </div>
    <div class="row mt-3" *ngIf="history.isFalling == 1">
      <div class="col-12 text-center">
        <h2>Hulló fóliából lett felhasználva.</h2>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="col-12 mb-2">
      <hr>
      <button class="cancelButton btn btn-outline-danger" mat-dialog-close>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
