import {HttpErrorResponse} from '@angular/common/http';
import {RestResponse} from './rest-response.service';

export class RequestError {

  public readonly errorCode: string;
  public readonly errorMessage: string;

  public static create(err: RestResponse | HttpErrorResponse): RequestError {
    if (err instanceof RestResponse) {
      return new RequestError(err.errorCode, err.errorMessage);
    } else if (err instanceof HttpErrorResponse) {
      return new RequestError(err.status.toString(), err.message);
    }
    return null;
  }

  constructor(errorCode: string, errorMessage: string) {
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }

}
