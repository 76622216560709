import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InventoryService} from '../../../Service/Inventory/inventory.service';
import {FormBuilder} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss']
})
export class HistoryDialogComponent implements OnInit, OnDestroy {
  unsubscribeAll: Subject<any>;
  history;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<HistoryDialogComponent>) {
    this.unsubscribeAll = new Subject();
    this.history = this.data.history;
  }

  ngOnInit(): void {
    console.log(this.history);
  }


  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

}
