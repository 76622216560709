import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../Service/User/user.service';

@Component({
  selector: 'app-product-lister',
  templateUrl: './product-lister.component.html',
  styleUrls: ['./product-lister.component.scss']
})
export class ProductListerComponent implements OnInit {
  @Input() product;
  @Input() showDate = true;
  user;
  constructor( private userService: UserService) {
    this.user = this.userService.getUser();
  }

  ngOnInit(): void {
  }

}
