import {isNullOrUndefined} from 'util';

export class RestResponse {

  readonly status: Status;
  readonly errorCode: string;
  readonly errorMessage: string;
  private readonly data: any[];

  public static create(rawResponse: any): RestResponse {
    return new RestResponse(rawResponse);
  }

  constructor(rawResponse: any) {
    this.status = rawResponse.status;
    this.errorCode = rawResponse.errorCode;
    this.errorMessage = rawResponse.errorMessage;
    this.data = rawResponse.data;
  }

  public isSuccess(): boolean {
    return this.status === Status.OK;
  }

  public getData(): any[] {
    return this.data;
  }

  public getFirstData(): any {
    return this.data[0];
  }

  public isEmpty(): boolean {
    return isNullOrUndefined(this.data) || this.data.length === 0;
  }


  public isNotEmpty(): boolean {
    return !this.isEmpty();
  }

}

enum Status {
  OK = 'OK',
  FAILED = 'FAILED'
}
