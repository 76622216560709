import { Injectable } from '@angular/core';
import {RestService} from '../Rest/rest.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private restService: RestService
  ) { }

  getInventory(pageSize, pageIndex = 1, filter): Observable<any> {
    filter.pageSize = pageSize;
    return this.restService.post(`ProductController/getProducts?page=${pageIndex}`, filter)
      .pipe(map(response => response.getData()));
  }
  getCompanies(): Observable<any> {
    return this.restService.get(`ProductController/getCompanies`)
      .pipe(map(response => response.getData()));
  }
  setIsActive(params): Observable<any> {
    return this.restService.post(`ProductController/setProductStatus`, params)
      .pipe(map(response => response.getFirstData()));
  }
  deleteProduct(id): Observable<any> {
    const param = {productId: id};
    return this.restService.post(`ProductController/deleteProduct`, param)
      .pipe(map(response => response.getData()));
  }
  pruductChange(op, product): Observable<any> {
    let route;
    switch (op) {
      case 'add': {
        route = 'ProductController/addOrSaveProduct';
        break;
      }
      case 'update': {
        route = 'ProductController/addOrSaveProduct';
        break;
      }
    }
    return this.restService.post(route, product)
      .pipe(map(response => response.getFirstData()));
  }

  export(filter): Observable<any> {
    return this.restService.post(`ProductController/exportProducts`, filter)
      .pipe(map(response => response.getFirstData()));
  }
}
