import { Injectable } from '@angular/core';
import {RestService} from '../Rest/rest.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FallingProductsService {

  constructor(
    private restService: RestService
  ) {
  }

  getFallingProductByProdId(prodId): Observable<any> {
    return this.restService.get(`FallingProductsController/getFallingProduct?productId=${prodId}`)
      .pipe(map(response => response.getData()));
  }
  deleteFallingProductsById(id): Observable<any> {
    return this.restService.get(`FallingProductsController/deleteFallingProductsById?id=${id}`)
      .pipe(map(response => response.getData()));
  }
  addFallingProduct(param): Observable<any> {
    return this.restService.post(`FallingProductsController/addFallingProduct`, param)
      .pipe(map(response => response.getData()));
  }
}
