import {AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FallingProductsService} from '../../../Service/falling-products/falling-products.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {NotificationServiceService} from "../../../Service/notification-service/notification-service.service";
import {DeleteDialogComponent} from "../../SharedModules/delete-dialog/delete-dialog.component";
import {UserDialogComponent} from "../../users/user-dialog/user-dialog.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-falling-dialog',
  templateUrl: './falling-dialog.component.html',
  styleUrls: ['./falling-dialog.component.scss']
})
export class FallingDialogComponent implements OnInit, AfterViewInit {
  creatingProduct = false;
  displayedColumns: string[] = ['width', 'length', 'edit','delete'];
  dataSource;
  loading = false;
  form;
  product;
  fallingProducts = [];
  private paginator: MatPaginator;
  private sort: MatSort;
  private unsubscribeAll: Subject<any>;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private notiService: NotificationServiceService,
              public dialogRef: MatDialogRef<FallingDialogComponent>,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private fallingProductsService: FallingProductsService) {
    this.unsubscribeAll = new Subject();
    this.product = this.data.product;
    this.dataSource = new MatTableDataSource();

  }

  ngOnInit(): void {
    this.getFallingProducts();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit() {
    console.log(this.paginator);
  }
  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getFallingProducts() {
    this.loading = true;
    this.fallingProductsService.getFallingProductByProdId(this.product.id).subscribe(
      res => {
        this.fallingProducts = res;
        this.dataSource = new MatTableDataSource(res);
        this.setDataSourceAttributes();
        this.loading = false;
      }
    );
  }

  createFallingProduct() {
    this.createForm([]);
    this.creatingProduct = true;
  }

  cancelProductCreating() {
    this.creatingProduct = false;
    this.getFallingProducts();
  }

  saveProduct() {
    this.fallingProductsService.addFallingProduct(this.form.getRawValue()).subscribe(
      res => {
        this.notiService.success('Sikeres mentés');
        this.creatingProduct = false;
        this.getFallingProducts();
      },
      error => {
        this.notiService.error(error.errorMessage);
        this.creatingProduct = false;
        this.getFallingProducts();
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createForm(fallingProduct) {
    this.creatingProduct = true;
    this.form = this.fb.group({
      productId: [this.product.id],
      fallingProductId: [fallingProduct.id],
      width: [fallingProduct.width, [Validators.max(3000), Validators.min(0), Validators.required]],
      length: [fallingProduct.length, [Validators.max(250), Validators.min(0), Validators.required]]
    });
  }

  deleteFallingProduct(id){
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '30%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '450px' : '550px',
      data: {text: 'Biztosan törölni akarod a hullótermékek közül a ' + id + ' termék id-val rendelkező terméked?'}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res === true) {
        this.fallingProductsService.deleteFallingProductsById(id).subscribe(
          res=>{
            this.notiService.success('Sikeres törlés');
            this.getFallingProducts();
          },error => {
            this.notiService.success(error.errorMessage);
          }
        );
      }
    });
  }

  get width() {
    return this.form.get('width') as FormArray;
  }

  get length() {
    return this.form.get('length') as FormArray;
  }
}
