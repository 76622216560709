import {Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {LoginComponent} from '../login/login.component';
import {isNullOrUndefined} from 'util';
import {UserService} from '../../../Service/User/user.service';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  private unsubscribeAll: Subject<any>;
  constructor(private breakpointObserver: BreakpointObserver,
              public dialog: MatDialog,
              private userService: UserService
  ) {
    this.unsubscribeAll = new Subject();
  }
  user;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Web || Breakpoints.XLarge || Breakpoints.Large)
    .pipe(
      map(result => {
        return !result.matches;
      }),
      shareReplay()
    );
  @ViewChild('drawer', {static: true}) drawer: MatSidenav;

  ngOnInit() {
    console.log(this.drawer);
    if (isNullOrUndefined(localStorage.getItem('accessToken'))) {
      this.openLogin();
    } else {
      this.getUser();
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getUser() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '30%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '450px' : '550px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.openLogin();
      } else {
        this.getUser();
      }
    });
  }

  logout() {
    this.userService.logout();
  }

  navigationClicked() {
    if (innerWidth < 1280) {
      this.drawer.close();
    }
  }
}
