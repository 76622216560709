import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Subject} from 'rxjs';
import {HistoryService} from '../../Service/History/history.service';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from '../../Service/User/user.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductUpdateDialogComponent} from '../Inventory/product-update-dialog/product-update-dialog.component';
import {UserDialogComponent} from './user-dialog/user-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationServiceService} from '../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  isLoading = false;
  dataSource;
  columnMobil = ['userName', 'name', 'role'];
  columnlaptop = ['id', 'userName', 'name', 'role', 'lastLogin', 'delete'];

  displayedColumns = innerWidth < 600 ? this.columnMobil : this.columnlaptop;
  private paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  private unsubscribeAll: Subject<any>;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private notiService: NotificationServiceService,
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.getUsers();
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // if (this.paginator && this.sort) {
    //   this.applyFilter('');
    // }
  }

  getUsers() {
    this.isLoading = true;
    this.userService.getUsers().subscribe(
      res => {
        this.dataSource = new MatTableDataSource(res);
        this.setDataSourceAttributes();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(user = [], isUpdate) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '40%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '500px' : '700px',
      data: {
        userData: user,
        isUpdate
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.getUsers();
      }
    });
  }

  deleteUser(event) {
    event.stopPropagation();
    this.notiService.error('Ez a funkció jelenleg nem üzemel!');
  }
}
