<h1 class="text-center mb-4" mat-dialog-title>{{isUpdate? 'Felhasználó módosítás': 'Új felhasználó létrehozása'}}</h1>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="userForm">
    <div class="row my-3">
<!--      <div class="col-1"></div>-->
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Név</mat-label>
          <input matInput formControlName="name" placeholder="Vámosi Róbert">
          <mat-hint>Név például <strong>Vámosi Róbert.</strong></mat-hint>
          <mat-error *ngIf="name.hasError('required')">
            Név megadása <strong>kötelező</strong>.
          </mat-error>
        </mat-form-field>
      </div>
<!--      <div class="col-1"></div>-->
    </div>
    <div class="row my-3">
<!--      <div class="col-1"></div>-->
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Felhasználónév</mat-label>
          <input matInput formControlName="userName" placeholder="vdrobi">
          <mat-hint>Felhasználónév például <strong> vdrobi.</strong></mat-hint>
          <mat-error *ngIf="userName.hasError('required')">
            Felhasználónév megadása <strong> kötelező</strong>.
          </mat-error>
        </mat-form-field>
      </div>
<!--      <div class="col-1"></div>-->
    </div>
    <div class="row my-3">
<!--      <div class="col-1"></div>-->
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Jelszó</mat-label>
          <input matInput formControlName="password" type="password">
          <mat-error *ngIf="userName.hasError('required')">
            Jelszó megadása <strong> kötelező</strong>.
          </mat-error>
        </mat-form-field>
      </div>
<!--      <div class="col-1"></div>-->
    </div>
    <div class="row my-3">
<!--      <div class="col-1"></div>-->
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Beosztás</mat-label>
          <mat-select [formControl]="role" >
            <mat-option [value]="3">Admin</mat-option>
            <mat-option [value]="2">Beosztott</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
<!--      <div class="col-1"></div>-->
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="col-6 text-left mb-3">
    <button class="btn btn-outline-danger" mat-dialog-close>Mégse</button>
  </div>
  <div class="col-6 text-right mb-3">
    <button class="btn btn-outline-success " [disabled]="userForm.invalid" (click)="save()" cdkFocusInitial>Mentés</button>
  </div>
</mat-dialog-actions>
