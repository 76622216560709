<div class="container-fluid">
  <div class="row pt-4 pb-3">
    <div class="col-12">
      <h1>Stock történet</h1>
      <h4>A mennyiség frissítésével kapcsolatos változtatások láthatóak itt (stockban történt módosítások).</h4>
    </div>
  </div>
  <div class="row mb-2" *ngIf="!isLoading; else LoadingSpinner">
<!--  <div class="row mb-2">-->
    <div class="col-12 mb-2">
      <mat-card class="mb-2">
        <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" autocomplete="off">
      </mat-form-field>
      </mat-card>
    </div>

    <div class="col-12" >
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>


          <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Termék neve</th>
            <td mat-cell *matCellDef="let row"> {{row.productName}} </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Elfogadó neve</th>
            <td mat-cell *matCellDef="let row"> {{row.whoSaved}}</td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Művelet</th>
            <td mat-cell *matCellDef="let row"> {{row.operation === 'add' ? 'Feltöltés' : row.operation === 'remove' ? 'Levétel' : 'Törlés'}} </td>
          </ng-container>

          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Méret</th>
            <td mat-cell *matCellDef="let row"> {{row.size | number}} {{row.operation ?  ' m' : ''}} </td>
          </ng-container>

          <ng-container matColumnDef="isFalling">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Hullófólia </th>
            <td mat-cell *matCellDef="let row"> {{row.isFalling == 1? 'Hulló': 'Nem hulló' }}</td>
          </ng-container>

          <ng-container matColumnDef="savedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mentve </th>
            <td mat-cell *matCellDef="let row"> {{row.savedAt | date:'y, MMM., d.'}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.reason.toUpperCase().includes('SELEJT')? 'noGood': ''"  (click)="openHistory(row)">
          </tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5,12,25,50,100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<ng-template #LoadingSpinner>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>
