import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {InventoryComponent} from './Main/Inventory/inventory.component';
import {StockComponent} from './Main/stock/stock.component';
import {HistoryComponent} from './Main/history/history.component';
import {SetProductStockComponent} from './Main/stock/set-product-stock/set-product-stock.component';
import {AuthenticationGuard} from './Service/authentication/authentication.guard';
import {UsersComponent} from './Main/users/users.component';
import {AdminGuard} from './Service/guards/admin.guard';
import {ProfileComponent} from './Main/profile/profile.component';
import {ProductInfoChangeHistoryComponent} from './Main/product-info-change-history/product-info-change-history.component';


const routes: Routes = [
  {path: '', redirectTo: 'inventory', pathMatch: 'full'},
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {path: 'inventory', component: InventoryComponent},
      {path: 'stock', component: StockComponent},
      {path: 'stock/:productId', component: SetProductStockComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'prodHistory', component: HistoryComponent},
      {path: 'prodInfoHistory', component: ProductInfoChangeHistoryComponent, canActivate: [AdminGuard]},
      {path: 'users', component: UsersComponent, canActivate: [AdminGuard]},
      {path: 'history', component: HistoryComponent, canActivate: [AdminGuard]}
    ]
  },
  {
    path: '**',
    redirectTo: 'inventory'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
