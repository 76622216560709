<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <h1 mat-dialog-title> Hullófóliák a <b>{{product.name}}</b> termékhez</h1>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div style="min-height: 200px">

      <ng-container *ngIf="!creatingProduct">
        <ng-container *ngIf="!loading;else LoadingSpinner">
          <div class="row" *ngIf="fallingProducts.length >0; else noData ">
            <div class="col-12 px-0">
              <mat-form-field appearance="outline">
                <mat-label>Szűrés</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Keress" #input autocomplete="off">
              </mat-form-field>

              <div class="mat-elevation-z8 mb-5">
                <table mat-table [dataSource]="dataSource" matSort>

                  <!--                &lt;!&ndash; ID Column &ndash;&gt;-->
                  <!--                <ng-container matColumnDef="id">-->
                  <!--                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Azonosító</th>-->
                  <!--                  <td mat-cell *matCellDef="let row"> {{row.id}} </td>-->
                  <!--                </ng-container>-->

                  <!-- Name Column -->
                  <ng-container matColumnDef="width">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Szélesség</th>
                    <td mat-cell *matCellDef="let row"> {{row.width}} mm</td>
                  </ng-container>

                  <!-- Color Column -->
                  <ng-container matColumnDef="length">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Hossz</th>
                    <td mat-cell *matCellDef="let row"> {{row.length}} m</td>
                  </ng-container>
                  <!-- Color Column -->
                  <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <button mat-icon-button matTooltip="Hullófólia szerkesztése" [matTooltipPosition]="'above'"
                              (click)="createForm(row)">
                        <mat-icon>create</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let row" class="text-right">
                      <button mat-icon-button matTooltip="Hullófólia törlése" [matTooltipPosition]="'above'"
                              (click)="deleteFallingProduct(row.id)">
                        <mat-icon color="warn">delete</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                  </tr>
                </table>

                <mat-paginator [pageSize]="10"></mat-paginator>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="creatingProduct">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Termék hossza</mat-label>
                <input matInput placeholder="Pl. 3.5m" type="number" formControlName="length" min="0" max="250"
                       autocomplete="off">
                <span matSuffix class="unit">m</span>
                <mat-error *ngIf="length.invalid">
                  Készletnek legalább <strong>0 méter</strong> és maximum <strong>250 méter</strong> adható meg.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Termék szélessége</mat-label>
                <input matInput placeholder="Pl. 1350mm" type="number" formControlName="width" min="0" max="3000"
                       autocomplete="off">
                <span matSuffix class="unit">mm</span>
                <mat-error *ngIf="width.invalid">
                  Termék szélességének legalább <strong>0 méter</strong> és maximum <strong>3000 méter</strong> adható
                  meg.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mt-1">
    <div class="col-4 px-0 mb-2">
      <button class="btn btn-outline-danger" *ngIf="!creatingProduct" mat-dialog-close>Mégse</button>
      <button class="btn btn-outline-danger" *ngIf="creatingProduct" (click)="cancelProductCreating()">Mégse</button>
    </div>
    <div class="col-8 px-0 mb-2 text-right">
      <button class="btn btn-outline-success" [disabled]="loading" *ngIf="!creatingProduct" (click)="createFallingProduct()">Hullófólia
        hozzáadása
      </button>
      <button class="btn btn-outline-success" [disabled]="form.invalid" *ngIf="creatingProduct" (click)="saveProduct()">
        Mentés
      </button>
    </div>
  </mat-dialog-actions>
</div>

<ng-template #noData>
  <div class="col-12 text-center">
    <h3>Nincs megjeleníthető adat.</h3>
  </div>
</ng-template>
<ng-template #LoadingSpinner>
  <div class="row my-auto align-items-center" style="height: 200px">
    <div class="col-12 text-center">
      <div class="spinner-grow text-dark"></div>
    </div>
  </div>

</ng-template>
