<div class="container-fluid">
  <div class="row py-4">
    <div class="col-3">
      <h1 class="">
        Leltár
      </h1>
    </div>
    <div class="col-9 text-right" *ngIf="!isLoading">
      <button class="btn btn-outline-primary" (click)="openUpdateDialog([], 'add')">Új termék létrehozása</button>
    </div>
    <div class="col-12 text-right" *ngIf="user.role === 3 && !isLoading">
      <button class="btn btn-outline-dark" (click)="export()">Termék export</button>
    </div>
  </div>
  <ng-container *ngIf="!isLoading; else LoadingSpinner">
    <!--  FILTER DATA-->
    <mat-accordion>
      <mat-expansion-panel [expanded]="innerWidth > 750">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Szűrő
          </mat-panel-title>
          <mat-panel-description>
            <span *ngIf="innerWidth >= 750">Szűrj szélességre, színekre, felületre, típusra, méretre, cégnévre,
              státuszra ...</span>
            <span *ngIf="innerWidth < 750">Számos dologra szűrhetsz itt</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12">
            <form [formGroup]="filterForm">
              <div class="row">
                <div class="col-lg-5 col-xl-5 col-sm-12 col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Kereső</mat-label>
                    <input formControlName="searchString"
                      placeholder="Keress szélességre, színekre, felületre, típusra.." matInput autocomplete="off">
                  </mat-form-field>
                </div>
                <div class="col-lg-4 col-xl-4 col-sm-12 col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Méret</mat-label>
                    <mat-select formControlName="sizeOptions" multiple>
                      <mat-option value="0">0m - 25m</mat-option>
                      <mat-option value="1">25m - 50m</mat-option>
                      <mat-option value="2">50m - 100m</mat-option>
                      <mat-option value="3">100m - 150m</mat-option>
                      <mat-option value="4">150m felett</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  class="col-lg-3 col-xl-3 col-sm-12 col-md-12 mx-auto text-sm-center text-md-center text-xl-right text-lg-right">
                  <mat-checkbox formControlName="onlyCritical" [labelPosition]="'after'">Csak kritikus mennyiség alatti
                    terméket mutass
                  </mat-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-xl-3 col-sm-12 col-md-12">
                  <mat-form-field appearance="outline">
                    <mat-label>Cég</mat-label>
                    <mat-select formControlName="company" multiple>
                      <mat-option *ngFor="let company of productsDetails['companies']" [value]="company">{{company}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                  <div class="col-lg-3 col-xl-3 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                      <mat-label>Típus</mat-label>
                      <mat-select formControlName="type" multiple>
                        <mat-option *ngFor="let type of productsDetails['types']" [value]="type">{{type}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                <div class="col-lg-3 col-xl-3 col-sm-8 col-md-8">
                  <mat-form-field appearance="outline">
                    <mat-label>Sátusz</mat-label>
                    <mat-select formControlName="status" multiple>
                      <mat-option [value]="1">Aktív</mat-option>
                      <mat-option [value]="0">Inaktív</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="col-lg-3 col-xl-3 col-sm-4 col-md-4 my-auto text-right">
                  <button class="btn btn-outline-primary" [disabled]="isLoading"
                    (click)="getInventory(innerWidth > 750 ? 12 : 6)">Keress</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>


    <!--  FILTER DATA-->
    <div class="row my-3">
      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 my-2" *ngFor="let inv of inventory">
        <mat-card class="example-card"
          [ngClass]="{'isNotActive': inv.isActive !== 1, 'underCritical': inv.underCriticalQuantity}">
          <mat-card-header>
            <div mat-card-avatar [class]="'_' + inv.company + '-example-header-image'"></div>
            <!--          <div mat-card-avatar class="my-auto text-center font-weight-bold"> <h2>{{inv.id}}</h2> </div>-->
            <mat-card-title class="wrap font18">
              {{inv.name}}
            </mat-card-title>
            <mat-card-subtitle>{{inv.company}}</mat-card-subtitle>
            <div class="text-right" *ngIf="innerWidth > 1450">
              <mat-slide-toggle color="primary" [checked]="inv.isActive === 1" (change)="statusChanged($event, inv)">
              </mat-slide-toggle>
            </div>
          </mat-card-header>
          <mat-card-content>
            <div class="row mt-2 mb-1">
              <div class="col-6 text-primary">
                <h3><strong>Készlet:</strong></h3>
              </div>
              <div class="col-6 text-right text-primary">
                <h3><strong>{{inv.stock}} m</strong></h3>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6"><strong>Kritikus mennyiség</strong></div>
              <div class="col-6 text-right">{{inv.criticalQuantity}} m</div>
            </div>
            <div class="row mb-2 mt-0">
              <div class="col-3"><strong>Módosítva:</strong></div>
              <div class="col-9 text-right" >
                <span matTooltip="{{inv.changed.reason}}" [matTooltipPosition]="'above'">{{inv.changed.userName}} - {{inv.changed.when | date: 'MMM, d hh:mm'}}<br>
                {{inv.changed.size}} {{inv.changed.size ? 'méter' : ''}} - {{inv.changed.operation ? (inv.changed.operation === 'add' ? 'Feltöltés' : 'Levétel') : ''}}</span>
              </div>
            </div>
            <div class="row mb-1 mt-0">
              <div class="col-12 text-right">
                <button class="btn btn-outline-dark btn-block" (click)="openFallingProductsDialog(inv)">Mutasd a
                  hullófóliákat</button>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="row">
              <div class="col-4">
                <button *ngIf="user.role === 3" class="btn btn-outline-danger"
                  (click)="deleteProduct(inv.id)">Törlés</button>
              </div>
              <div class="col-8 text-right">
                <button mat-icon-button class="mr-2" matTooltip="Stockhoz adás" [matTooltipPosition]="'above'"
                  (click)="areYouSureAlert(inv)">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
                <button mat-icon-button class="mr-2" matTooltip="Termék info" [matTooltipPosition]="'above'"
                  (click)="openInfoDialog(inv)">
                  <mat-icon>info</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Termék info frissítése" [matTooltipPosition]="'above'"
                  (click)="openUpdateDialog(inv,'update')">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Termék klónozása" [matTooltipPosition]="'above'"
                  (click)="openUpdateDialog(inv,'add')">
                  <mat-icon>flip_to_front</mat-icon>
                </button>
              </div>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </ng-container>
  <div class="row" [hidden]="isLoading">
    <div class="col-12">
      <mat-paginator [length]="totalCount" [pageSize]="innerWidth > 750? 12 : 6" [pageSizeOptions]="[5,12,25,50,100]"
        (page)="paginate($event)">
      </mat-paginator>
    </div>
  </div>

</div>

<ng-template #LoadingSpinner>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>