<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <h1 class="my-4">
        Stock
      </h1>
    </div>
    <div class="col-6 text-right my-4">
      <button class="btn btn-outline-dark" (click)="getStock()">Stock lekérdezés</button>
    </div>
    <div class="col-12">
      <h3>Egy termék mennyiségének módosításánál, nem elmentett adatok láthatóak itt.</h3>
    </div>
  </div>
  <div class="row mb-2"*ngIf="!isLoading">
    <div class="col-12 mb-2">
      <mat-card class="mb-2">
        <mat-form-field appearance="outline">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" autocomplete="off">
        </mat-form-field>
      </mat-card>
    </div>
  </div>
  <div class="row" *ngIf="!isLoading; else LoadingSpinner">
<!--  <div class="row">-->
    <div class="col-12">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <!-- ID Column -->
          <ng-container matColumnDef="stockId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock Id</th>
            <td mat-cell *matCellDef="let row"> {{row.stockId}} </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Termék</th>
            <td mat-cell *matCellDef="let row"> {{row.productName}}</td>
          </ng-container>

          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Felhasználó </th>
            <td mat-cell *matCellDef="let row"> {{row.userName}}</td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Létrehozva </th>
            <td mat-cell *matCellDef="let row"> {{width > 500 ? (row.created | date:'y, MMM., d. hh:mm') : (row.created  | date:'y, MMM., d.') }}</td>
            <td mat-cell *matCellDef="let row"> {{row.created | date:'y, MMM, d'}}</td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
            <td mat-cell *matCellDef="let row"> <button class="btn btn-outline-danger deleteButton" (click)="deleteFromStock(row.stockId, $event)"> Törlés </button> </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openStock(row)">
          </tr>
        </table>

        <mat-paginator pageSize="10" [pageSizeOptions]="[5,12,25,50,100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #LoadingSpinner>
  <app-loading-spinner [centered]="true"></app-loading-spinner>
</ng-template>
