import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {InventoryService} from '../../Service/Inventory/inventory.service';
import {MatTableDataSource} from '@angular/material/table';
import {StockService} from '../../Service/Stock/stock.service';
import {MatDialog} from '@angular/material/dialog';
import {StockDialogComponent} from './stock-dialog/stock-dialog.component';
import {DeleteDialogComponent} from '../SharedModules/delete-dialog/delete-dialog.component';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationServiceService} from '../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit, OnDestroy {
  width = innerWidth;
  displayedColumns: string[] = this.width < 500 ? ['productName', 'userName', 'created'] : ['stockId', 'productName', 'userName', 'created', 'delete'];
  dataSource;
  stock;
  isLoading = false;
  private paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  private unsubscribeAll: Subject<any>;

  constructor(public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private notiService: NotificationServiceService,
              private stockService: StockService
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.getStock();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
  getStock() {
    this.isLoading = true;
    this.stockService.getStocks().subscribe(
      res => {
        this.stock = res;
        console.log(this.stock);
        this.dataSource = new MatTableDataSource(this.stock);
        this.setDataSourceAttributes();
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openStock(row) {
    const dialogRef = this.dialog.open(StockDialogComponent, {
      width: '40%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '500px' : '700px',
      data: {
        stock: row
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res === true) {
        this.getStock();
      }

    });
  }

  deleteFromStock(id: any, event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '30%',
      minWidth: innerWidth < 500 ? '350px' : innerWidth < 800 ? '450px' : '550px',
      data: {text: 'Biztosan törölni akarod a stockból a ' + id + ' stock id-val rendelkező sort?'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stockService.deleteFromStock(id).subscribe(
          res => {
            this.notiService.success('Sikeres törlés.');
            this.getStock();
          },
          error => {
            this.notiService.error(error.errorMessage);
            console.log(error);
          }
        );
      }
    });
  }
}
