import {Component, OnInit} from '@angular/core';
import {UserService} from '../../Service/User/user.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationServiceService} from '../../Service/notification-service/notification-service.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user;
  updateForm;
  update;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private notiService: NotificationServiceService,
    private snackBar: MatSnackBar
  ) {
    this.user = this.userService.getUser();
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.update = true;
    this.userService.getCurrentUser(this.user.id).subscribe(
      res => {
        this.user = res;
        this.createForm();
        this.update = false;
      },
      error => {
        console.log(error);
        this.update = false;
      }
    );
  }

  save() {
    this.userService.registration(this.updateForm.getRawValue(), true).subscribe(
      res => {
        this.notiService.success('Sikeresen módosítottad az adataidat.');
        this.user = res;
      },
      error => {
        this.notiService.error(error.errorMessage);
      }
    );
  }

  createForm() {
    console.log(this.user);
    this.updateForm = this.fb.group({
      userName: [this.user.userName, []],
      password: ['', [Validators.required]],
      role: [this.user.role, []],
      id: [this.user.id, []],
      name: [this.user.name, []],
    });
  }

  get userName() {
    return this.updateForm.get('userName') as FormArray;
  }

  get password() {
    return this.updateForm.get('password') as FormArray;
  }

  get role() {
    return this.updateForm.get('role') as FormArray;
  }

  get id() {
    return this.updateForm.get('id') as FormArray;
  }

  get name() {
    return this.updateForm.get('name') as FormArray;
  }


}
